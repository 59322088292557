import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { z } from "zod";

const maxEarningsSchema = z.object({
  amount: z.number(),
  date: z.string().optional(),
});

export type MaxEarningsResponse = z.infer<typeof maxEarningsSchema>;

export function useMyShiftsMaxEarnings(options: UseGetQueryOptions<MaxEarningsResponse> = {}) {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/calendar/myShifts/maxEarnings`,
    responseSchema: maxEarningsSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_MAX_EARNINGS_FAILURE,
      userErrorMessage: "Something went wrong while retrieving available shifts",
    },
    ...options,
  });
}
